<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12">
      <div class="card card-body">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered custom-table">
            <thead>
              <th>رقم الطلب</th>
              <th>تاريخ الطلب</th>
              <th>الجهاز</th>
              <th>الكمية</th>
              <th>اجمالي المبلغ</th>
              <th>الحالة</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr v-for="order in orders" :key="order._id">
                <td>
                  {{ order.order_id }}
                </td>
                <td>
                  {{ order.date }}
                </td>
                <td>
                  {{ order.device.title }}
                </td>
                <td>
                  {{ order.count }}
                </td>
                <td style="font-family: Arial !important">
                  {{ order.total }} ريال
                </td>
                <td>
                  <span
                    class="badge bg-warning text-dark"
                    v-if="order.status == 0"
                    >في انتظار التحقق من الدفع</span
                  >
                  <span class="badge bg-primary" v-if="order.status == 1"
                    >تم التأكد من الدفع وفي انتظار قبول الطلب</span
                  >
                  <span class="badge bg-primary" v-if="order.status == 2"
                    >تم قبول الطلب وفي انتظار التوصيل</span
                  >
                  <span class="badge bg-info" v-if="order.status == 3"
                    >جاري التوصيل</span
                  >
                  <span class="badge bg-success" v-if="order.status == 4"
                    >تم التوصيل</span
                  >
                  <span class="badge bg-success" v-if="order.status == 5"
                    >تم التركيب</span
                  >
                  <span class="badge bg-danger" v-if="order.status == 6"
                    >تم رفض الدفع</span
                  >
                  <span class="badge bg-danger" v-if="order.status == 7"
                    >حدث مشكلة</span
                  >
                  <span class="badge bg-danger" v-if="order.status == 8"
                    >تم الالغاء</span
                  >
                </td>
                <td>
                  <!-- primary -->
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    text="خيارات"
                    size="sm"
                    right
                    variant="primary"
                  >
                    <b-dropdown-item @click="eorder = order" v-b-modal.modal-1>
                      <i class="fa fa-info"></i>
                      تفاصيل الطلب
                    </b-dropdown-item>
                    <b-dropdown-item @click="eorder = order" v-b-modal.modal-2>
                      <i class="fa fa-print"></i>
                      طباعة الفاتورة
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12 text-center g">
      <div class="form-group">
        <label for="">تغيير اسم العميل (سيظهر في الفاتورة)</label>
        <input type="text"
          class="form-control c" placeholder="اكتب هنا..." style="max-width: 200px;" v-model="user.name">
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="تفاصيل الطلب"
      ok-only
      hide-footer
      ok-title="Accept"
    >
      <div class="col-12 table-responsive">
        <table class="table table-hover table-bordered">
          <tbody>
            <tr>
              <td>الجوال</td>
              <td>{{ eorder.phone }}</td>
            </tr>
            <tr>
              <td>العنوان</td>
              <td>{{ eorder.address }}</td>
            </tr>
            <tr>
              <td>ملاحظات العميل</td>
              <td>{{ eorder.notes }}</td>
            </tr>
            <tr>
              <td>ملاحظات المسؤول</td>
              <td>{{ eorder.admin_notes }}</td>
            </tr>
            <tr>
              <td>الجهاز</td>
              <td>{{ eorder.device.title }}</td>
            </tr>
            <tr>
              <td>الكمية</td>
              <td>{{ eorder.count }}</td>
            </tr>
            <tr>
              <td>اجمالي المبلغ</td>
              <td>{{ eorder.total }}</td>
            </tr>
            <tr>
              <td>يشمل التركيب</td>
              <td>{{ eorder.install ? "نعم" : "لا" }}</td>
            </tr>
            <tr>
              <td>ايصال الدفع</td>
              <td>
                <a :href="eorder.file" target="_blank"
                  ><i class="fa fa-file"></i> عرض الملف</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="modal-2"
      title="طباعة الطلب"
      ok-only
      hide-footer
      ok-title="Accept"
    >
      <div class="col-12 table-responsive tableaa">
        <table class="table table-hover table-bordered">
          <tbody>
            <tr>
              <td>رقم الطلب</td>
              <td>{{ eorder.order_id }}</td>
            </tr>
            <tr>
              <td>الجوال</td>
              <td>{{ eorder.phone }}</td>
            </tr>
            <tr>
              <td>العنوان</td>
              <td>{{ eorder.address }}</td>
            </tr>
            <tr>
              <td>ملاحظات العميل</td>
              <td>{{ eorder.notes }}</td>
            </tr>
            <tr>
              <td>الجهاز</td>
              <td>{{ eorder.device.title }}</td>
            </tr>
            <tr>
              <td>السعر (لكل جهاز)</td>
              <td>{{ eorder.device.price }}</td>
            </tr>
            <tr>
              <td>الكمية</td>
              <td>{{ eorder.count }}</td>
            </tr>
            <tr>
              <td>يشمل التركيب</td>
              <td>
                {{
                  eorder.install
                    ? "نعم" +
                      " " +
                      `(${eorder.place.install_price} ريال لكل جهاز)`
                    : "لا"
                }}
              </td>
            </tr>
            <tr>
              <td>يشمل التوصيل</td>
              <td>
                {{
                  true
                    ? "نعم" +
                      " " +
                      `(${eorder.place.delivery_price} ريال لكل جهاز)`
                    : "لا"
                }}
              </td>
            </tr>
            <tr>
              <td>
                اجمالي المبلغ الخاضع للضريبة	
              </td>
              <td>
                {{ eorder.original }} ريال
              </td>
            </tr>
            <tr>
              <td>
                ضريبة القيمة المضافة 15%	
              </td>
              <td>
                {{ eorder.tax }} ريال
              </td>
            </tr>
            <tr>
              <td>اجمالي المبلغ</td>
              <td>{{ eorder.total }} ريال</td>
            </tr>
            <tr>
              <td>اسم العميل</td>
              <td>{{ user.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-success" @click="print()">
          <i class="fa fa-print"></i> طباعة الآن
        </button>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BOverlay,
  BButton,
  BCardBody,
  BCard,
  BDropdown,
  VBModal,
  BDropdownItem,
  BLink,
  BImg,
  BCardText,
} from "bootstrap-vue";
export default {
  components: {
    BOverlay,
    BButton,
    BCardBody,
    BCard,
    BDropdown,
    BDropdownItem,
    BLink,
    BImg,
    BCardText,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user") ?? '{}'),
      orders: [],
      loading: true,
      eorder: {
        device: {},
        place: {},
      },
      loggedin: localStorage.getItem("user") ? true : false,
    };
  },
  created() {
    if (this.loggedin) {
      var g = this;
      $.post(api + "/client/devices/orders", {
        token: JSON.parse(localStorage.getItem("user")).token,
      })
        .then(function (r) {
          if (r.status == 100) {
            g.orders = r.response;
          } else {
            alert("حدث خطأ في الخادم، برجاء التواصل مع الدعم الفني");
          }
          g.loading = false;
        })
        .catch(function () {
          alert("حدث خطأ في الخادم، برجاء التواصل مع الدعم الفني");
          g.loading = false;
        });
    } else {
      this.$router.push("/login");
    }
  },
  methods: {
    print() {
      //Get the HTML of div
      var divElements = $(".tableaa:visible").html();
      var newWin = window.open("",
"tahdir.net","menubar=1,resizable=1,width=360,height=550");
      newWin.document.write(
        `<style>table, td, th {
  border: 1px solid;
}
*{
  direction:rtl
}
table {
  width: 100%;
  border-collapse: collapse;
}</style><h5>

          المملكة العربية السعودية
          <br>
          مؤسسة الصدى لتقنية المعلومات
          <br>
          سجل تجاري: 1128183340
          <br>
          جوال: 0580200670
          <br>
          الرقم الضريبي: 310458863500003
          <br>فاتورة شراء اجهزة بصمة من التحضير الذكي
        </h5>` + divElements
      );
      newWin.print();
      newWin.document.close()
    },
  },
};
</script>


<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>